import * as React from "react";
import Popup from "../../common/popup.js";
import * as Icons from "../../icons";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import { getDomainsBySpecificity } from "../../../services/api.js";
import { useGestionContext } from "../../common/contexts/getion-context.js";

const InfoSpecificityPopup = ({ popup }) => {
  const { setPopup } = useGestionContext();
  const [domains, setDomains] = React.useState([]);
  const kind = popup.data.kind;
  const name = popup.data.name;
  const closePopup = () => {
    setPopup(undefined);
  };

  React.useEffect(() => {
    const getDomains = async () => {
      const body = { name, kind };
      const response = await getDomainsBySpecificity(body);
      setDomains(response);
    };

    getDomains();
  }, [kind, name]);

  const buttons = [{ message: "Fermer", action: closePopup }];

  return (
    <Popup CloseIcon={Icons.Close} closeEffect={closePopup} buttons={buttons}>
      <h2 className="popup-title">
        Informations {kind}
        <br />
        {name}
      </h2>
      <div className="popup-spe-info">
        {!domains[0] && <LoaderBar />}
        {domains.map((elmt, index) => {
          const style =
            index % 2 === 0
              ? { backgroundColor: "var(--color-ice-light)" }
              : undefined;
          return (
            <span
              key={elmt.name + "infos"}
              className="popup-spe-info__span"
              style={style}
            >
              {elmt.name}
            </span>
          );
        })}
      </div>
    </Popup>
  );
};

export default InfoSpecificityPopup;
