import * as React from "react";
import classnames from "classnames";
import Button from "@valraiso-esf/esf-ui/es/button";
import Switch from "@valraiso-esf/esf-ui/es/switch";
import Textarea from "@valraiso-esf/esf-ui/es/textarea";
import { handleInputDeletion } from "../services/handle-input";
import { Navigate } from "react-router-dom";
import { useAppContext } from "./common/contexts/app-context";
import useSetPage from "../hooks/use-set-page";

const checkIfFormIsInvalid = (domains) => {
  return !domains;
};

const getErrorMessage = (isInvalid) => {
  if (!isInvalid) return;
  return "Vous devez renseigner au moins 1 domaine";
};

const Deletion = () => {
  const {
    loggedIn,
    setLoading,
    loading,
    domainsValue,
    setDomainsValue,
    setSelectedPage,
  } = useAppContext();
  const [logs, setLogs] = React.useState([]);
  const [errorOnlyChecked, setErrorOnlyChecked] = React.useState(false);
  const outputRef = React.useRef(null);

  useSetPage(setSelectedPage, "/deletion");

  const toggleErrorOnly = () => {
    setErrorOnlyChecked(!errorOnlyChecked);
  };

  const updateDomainsValue = (e) => {
    const { value } = e.target;
    setDomainsValue(value);
  };

  const isInvalid = checkIfFormIsInvalid(domainsValue);

  const submitForm = async (e) => {
    e.preventDefault();
    if (isInvalid) return;

    setLogs([]);
    setLoading(true);

    await handleInputDeletion(domainsValue, setLogs);
    setLoading(false);
    window.scrollTo({
      top: outputRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const errorMessage = getErrorMessage(isInvalid);

  const filteredLogs = errorOnlyChecked
    ? logs.filter((log) => log.status === "error")
    : logs;

  if (!loggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <form className="form" onSubmit={submitForm}>
        <span form="form__subtitle">Domaines</span>
        <Textarea
          placeholder="1 par ligne"
          className="form__domains"
          onChange={updateDomainsValue}
          value={domainsValue}
          resize="vertical"
          error={errorMessage}
          spellCheck="false"
          required
        />
        <Button
          className="form__validate"
          type="submit"
          disabled={loading || isInvalid}
        >
          Valider
        </Button>
        <div ref={outputRef}>
          <div className="form__output">
            {filteredLogs.map((log, i) => {
              const { status, domain, message, url } = log;

              const classes = classnames("form__log", {
                "form__log--error": status === "error",
                "form__log--url": url,
              });

              return (
                <div key={i}>
                  {">"} <b className={classes}>{domain}</b>{" "}
                  {url && (
                    <a
                      className={classes}
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {url}
                    </a>
                  )}
                  {message}
                </div>
              );
            })}
          </div>
        </div>
        <Switch checked={errorOnlyChecked} onChange={toggleErrorOnly}>
          Afficher seulement les erreurs
        </Switch>
      </form>
    </div>
  );
};

export default Deletion;
