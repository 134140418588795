import * as React from "react";
import Button from "@valraiso-esf/esf-ui/es/button";
import Tooltip from "@valraiso-esf/esf-ui/es/tooltip";
import Tabs from "../common/tabs";
import { useAppContext } from "../common/contexts/app-context";

const Menu = () => {
  const { setLoggedIn, selectedPage, setSelectedPage } = useAppContext();

  const logout = () => {
    setLoggedIn(false);
    window.localStorage.removeItem("password");
  };

  const allTabs = [
    { title: "Gestion", page: "/gestion" },
    { title: "Création", page: "/creation" },
    { title: "Suppression", page: "/deletion" },
  ];

  return (
    <div className="menu">
      <div className="menu__navigation">
        <Tooltip title="Accéder à plausible.io" position="right">
          <a href="https://plausible.io/sites" target="_blank" rel="noreferrer">
            <img
              className="menu__img"
              src="/plausible.png"
              alt="plausible-logo"
            />
          </a>
        </Tooltip>
        <Tabs
          elements={allTabs}
          selected={selectedPage}
          setSelected={setSelectedPage}
        />
        <Button className="menu__logout" onClick={logout}>
          Déconnexion
        </Button>
      </div>
    </div>
  );
};

export default Menu;
