import * as React from "react";
import { getDomains, getLastSync } from "../../../services/api";

const GestionContext = React.createContext();

export const useGestionContext = () => {
  const context = React.useContext(GestionContext);
  if (!context)
    throw new Error(
      `useGestionContext must be used within a GestionContextProvider`
    );
  return context;
};

const GestionContextProvider = (props) => {
  const [lastSync, setLastSync] = React.useState("");
  const [domains, setDomains] = React.useState([]);
  const [activeDomain, setActiveDomain] = React.useState({});
  const [popup, setPopup] = React.useState();

  React.useEffect(() => {
    const runEffect = async () => {
      const [allDomains, { date }] = await Promise.all([
        getDomains(),
        getLastSync(),
      ]);
      const formatedDate = new Date(date).toLocaleDateString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      setDomains(allDomains);
      setLastSync(formatedDate);
    };
    runEffect();
  }, []);
  return (
    <GestionContext.Provider
      value={{
        lastSync,
        domains,
        setDomains,
        activeDomain,
        setActiveDomain,
        popup,
        setPopup,
      }}
      {...props}
    />
  );
};

export default GestionContextProvider;
