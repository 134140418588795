import { updateIncomeSettings, addDomainIncomeSettings } from "../services/api";

export const updateSettings = async (
  newData,
  libelle,
  settings,
  setUpdate,
  data,
  setSettings
) => {
  if (newData === false) {
    setUpdate(undefined);
    return;
  }
  const newAllTypes = [...settings.allTypes];
  if (data.id === 0) {
    const newId = settings.allTypes.reduce(
      (total, data) => (data.id > total ? data.id : total),
      0
    );
    newAllTypes.push({
      id: newId + 1,
      libelle,
      orderTable: newData.orderTable,
      tableRow: newData.tableRow,
    });
    setSettings({ ...settings, allTypes: newAllTypes });
    setUpdate(undefined);
    await addDomainIncomeSettings({
      id: newId + 1,
      libelle,
      orderTable: newData.orderTable,
      tableRow: newData.tableRow,
    });
    return;
  }
  const index = newAllTypes.findIndex((obj) => obj.id === newData.id);
  if (
    index === -1 ||
    JSON.stringify(newAllTypes[index]) === JSON.stringify(newData)
  ) {
    setUpdate(undefined);
    return;
  }
  newAllTypes[index].orderTable = newData.orderTable;
  newAllTypes[index].tableRow = newData.tableRow;
  setSettings({ ...settings, allTypes: newAllTypes });
  setUpdate(undefined);
  await updateIncomeSettings({
    id: newData.id,
    orderTable: newData.orderTable,
    tableRow: newData.tableRow,
  });
};
