import * as React from "react";
import { Navigate } from "react-router-dom";
import Input from "@valraiso-esf/esf-ui/es/input";
import Button from "@valraiso-esf/esf-ui/es/button";
import { login } from "../../services/api";
import { useAppContext } from "../common/contexts/app-context";

const LoginForm = () => {
  const { loggedIn, setLoggedIn, loading, setLoading } = useAppContext();
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState();

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await login(password);
      if (response.success) {
        setLoggedIn(true);
        window.localStorage.setItem("password", password);
      }
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  const updatePassword = (e) => {
    const { value } = e.target;
    setPassword(value);
    if (!value.trim()) {
      setError("Le mot de passe ne peut pas être vide");
    }
  };

  if (loggedIn) {
    return <Navigate to="/gestion" />;
  }

  return (
    <div className="login-form">
      <form className="login-form__inner" onSubmit={submitForm}>
        <div className="login-form__header">
          <img
            className="login-form__logo"
            src="https://raw.githubusercontent.com/plausible/docs/master/static/img/plausible-analytics-icon-top.png"
            alt="plausible-logo"
          />
          <h2 className="login-form__title">
            Back-office <b>Plausible</b>
          </h2>
        </div>
        <Input
          type="password"
          name="password"
          label="Mot de passe :"
          value={password}
          onChange={updatePassword}
          error={error}
          required
        />
        <Button type="submit" disabled={loading}>
          Connexion
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
