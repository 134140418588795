import * as React from "react";
import Input from "@valraiso-esf/esf-ui/es/input";
import {
  getGoals,
  getLink,
  addSpecificity,
  getSpecificities,
} from "../../../services/api.js";
import Popup from "../../common/popup.js";
import * as Icons from "../../icons";
import { useGestionContext } from "../../common/contexts/getion-context.js";

const AddSpecificityPopup = () => {
  const { setPopup, activeDomain, setActiveDomain } = useGestionContext();
  const [kind, setKind] = React.useState("");
  const [name, setName] = React.useState("");

  const updateKind = (e) => {
    setKind(e.target.value);
  };

  const updateName = (e) => {
    setName(e.target.value);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (kind === "" || name === "") return;
    const body = {
      name,
      kind,
      domain: activeDomain.name,
    };
    await addSpecificity(body);
    const [goals, link, specificities] = await Promise.all([
      getGoals(activeDomain.name),
      getLink(activeDomain.name),
      getSpecificities(activeDomain.name),
    ]);
    const domainName = activeDomain.name;
    setActiveDomain({
      name: domainName,
      goals,
      link: link.link,
      deleted: activeDomain.deleted,
      specificities,
    });
    setPopup(undefined);
  };

  const closePopup = () => {
    setPopup(undefined);
  };

  const buttons = [
    { message: "Annuler", action: closePopup },
    { message: "Ajouter", action: submitForm },
  ];

  return (
    <Popup CloseIcon={Icons.Close} closeEffect={closePopup} buttons={buttons}>
      <h2 className="popup-title">Nouvelle spécificité</h2>
      <div className="popup-name">
        <Input
          value={kind}
          onChange={updateKind}
          placeholder="Type: ex Région"
          required
        />
      </div>
      <div className="popup-name">
        <Input
          value={name}
          onChange={updateName}
          placeholder="Nom: ex HAUTE SAVOIE"
          required
        />
      </div>
    </Popup>
  );
};

export default AddSpecificityPopup;
