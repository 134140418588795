import * as React from "react";
import * as Icons from "../../icons";
import CopyButton from "../../common/copied";
import Permissions from "./permissions";
import { getTokensList } from "../../../services/api";
import { useGestionContext } from "../../common/contexts/getion-context";

const Users = () => {
  const { setPopup, domains } = useGestionContext();
  const [tokensList, setTokensList] = React.useState([]);
  const [permissions, setPermissions] = React.useState();

  React.useEffect(() => {
    const exec = async () => {
      const response = await getTokensList();
      setTokensList(response);
    };
    exec();
  }, []);

  return (
    <div className="gestion-users" id="tokens-gestion">
      {permissions && (
        <Permissions
          setPermissions={setPermissions}
          domains={domains}
          token={permissions}
        />
      )}
      <div className="gestion-users__title">
        Gestion Tokens:
        <button
          className="gestion-users__title__button"
          onClick={() => setPopup({ name: "createToken" })}
        >
          <Icons.AddSign className="gestion-users__title__button__icon" />
          Nouveau
        </button>
      </div>
      <div className="gestion-users__list">
        <div className="gestion-users__list__line">
          <div
            className="gestion-users__list__line__name"
            style={{ backgroundColor: "var(--color-ice-light)" }}
          >
            Libellé
          </div>
          <div
            className="gestion-users__list__line__token"
            style={{ backgroundColor: "var(--color-ice-light)" }}
          >
            Token
          </div>
          <div
            className="gestion-users__list__line__date"
            style={{ backgroundColor: "var(--color-ice-light)" }}
          >
            Date
          </div>
        </div>
        {tokensList.map((elmt) => {
          return (
            <div key={elmt.token} className="gestion-users__list__line">
              <div className="gestion-users__list__line__name">{elmt.name}</div>
              <div className="gestion-users__list__line__token">
                {elmt.token.substring(0, 6) + "********************"}
                <CopyButton
                  value={elmt.token}
                  className="gestion-users__copy"
                />
              </div>
              <div className="gestion-users__list__line__date">
                {new Date(elmt.date).toLocaleDateString("fr-FR")}
                <div className="gestion-users__list__line__date__buttons">
                  <button
                    className="gestion-users__list__line__date__button"
                    style={{ right: "30px" }}
                    onClick={() => setPermissions(elmt.token)}
                  >
                    <Icons.Settings className="gestion-users__list__line__date__icon" />
                  </button>
                  <button
                    className="gestion-users__list__line__date__button"
                    onClick={() =>
                      setPopup({
                        name: "removeToken",
                        data: { token: elmt.token },
                      })
                    }
                  >
                    <Icons.Delete className="gestion-users__list__line__date__icon" />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Users;
