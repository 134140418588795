import * as React from "react";
import * as Icons from "../../icons";
import Popup from "../../common/popup";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import UpdateMonitorankSettings from "./update";
import classnames from "classnames";
import { useGestionContext } from "../../common/contexts/getion-context";
import { useMonitorankContext } from "../../common/contexts/monitorank-context";

const MonitorankSettings = () => {
  const { setPopup } = useGestionContext();
  const { isLoading, action, setAction, searchConsoles, keywords } =
    useMonitorankContext();

  const buttons = ["SUPPRIMER", "AJOUTER"];

  const classes = classnames("monitorank__button", {
    "monitorank__button--off": isLoading,
  });

  return (
    <Popup
      CloseIcon={Icons.Close}
      closeEffect={() => setPopup(undefined)}
      className="incomes-settings__popup"
    >
      <div className="monitorank__zone">
        {!action &&
          buttons.map((button) => {
            return (
              <button
                key={"monitorank" + button + "button"}
                className={classes}
                onClick={() => setAction(button)}
                disabled={isLoading}
              >
                {button}
              </button>
            );
          })}
        {isLoading && <LoaderBar className="monitorank__loading" />}
        {action && searchConsoles && keywords && <UpdateMonitorankSettings />}
      </div>
    </Popup>
  );
};

export default MonitorankSettings;
