import Button from "@valraiso-esf/esf-ui/es/button";
import * as React from "react";
import * as Icons from "../../icons";

const Cell = ({ elmt, index, column }) => {
  const [value, setValue] = React.useState(elmt);

  const udpateValue = (e) => {
    setValue(e.target.value);
  };

  return (
    <input
      id={column.title + elmt + index + "grid"}
      className="incomes-settings__type__update__grid"
      value={value}
      onChange={udpateValue}
    />
  );
};

const Grid = ({ options, columns, onValidation }) => {
  const [data, setData] = React.useState(options);

  const updateOptions = () => {
    const newOptions = { ...data };
    columns.map((column) => {
      const newColumn = data[column.name].map((elmt, index) => {
        const element = document.getElementById(
          column.title + elmt + index + "grid"
        );
        return element.value;
      });
      newOptions[column.name] = newColumn.filter((obj) => obj !== "");
      return newColumn;
    });
    const libelle =
      data.id === 0 ? document.getElementById("grid__title").value : undefined;
    onValidation(newOptions, libelle);
  };

  return (
    <div className="incomes-settings__type__update">
      {data.id === 0 && (
        <input
          id="grid__title"
          placeholder="Nouveau"
          className="incomes-settings__type__update__title"
        />
      )}
      <div className="incomes-settings__type__update__content">
        {columns.map((column) => {
          return (
            <div
              key={column.title + "column"}
              className="incomes-settings__type__update__column"
            >
              <b className="incomes-settings__type__update__grid incomes-settings__type__update__header">
                {column.title}
              </b>
              {data[column.name].map((elmt, index) => {
                return (
                  <Cell
                    key={column.title + elmt + index + "grid"}
                    elmt={elmt}
                    index={index}
                    column={column}
                  />
                );
              })}
            </div>
          );
        })}
        <button
          className="incomes-settings__type__update__new__button"
          onClick={() =>
            setData({
              ...data,
              orderTable: [...data.orderTable, ""],
              tableRow: [...data.tableRow, ""],
            })
          }
        >
          <Icons.DownExpand />
        </button>
      </div>
      <div className="incomes-settings__type__update__buttons">
        <Button
          onClick={() => {
            if (data.id === 0) {
              onValidation(false);
            } else {
              onValidation(options);
            }
          }}
        >
          Annuler
        </Button>
        <Button onClick={updateOptions}>Valider</Button>
      </div>
    </div>
  );
};

export default Grid;
