import * as React from "react";
import Input from "@valraiso-esf/esf-ui/es/input";
import {
  getGoals,
  getLink,
  createGoals,
  getSpecificities,
} from "../../../services/api.js";
import Popup from "../../common/popup.js";
import * as Icons from "../../icons";
import { useGestionContext } from "../../common/contexts/getion-context.js";

const AddGoalPopup = ({ type }) => {
  const { setPopup, activeDomain, setActiveDomain } = useGestionContext();
  const [kind, setKind] = React.useState(type);
  const [name, setName] = React.useState("");

  const setKindPage = () => {
    setKind("page");
  };

  const setKindEvent = () => {
    setKind("event");
  };

  const updateName = (e) => {
    setName(e.target.value);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (kind === "page") {
      await createGoals([activeDomain.name], [`/${name}`], kind);
    } else {
      await createGoals([activeDomain.name], [name], kind);
    }
    const [goals, link, specificities] = await Promise.all([
      getGoals(activeDomain.name),
      getLink(activeDomain.name),
      getSpecificities(activeDomain.name),
    ]);
    const domainName = activeDomain.name;
    setActiveDomain({
      name: domainName,
      goals,
      link: link.link,
      deleted: activeDomain.deleted,
      specificities,
    });
    setPopup(undefined);
  };

  const closePopup = () => {
    setPopup(undefined);
  };

  const buttons = [
    { message: "Annuler", action: closePopup },
    { message: "Ajouter", action: submitForm },
  ];

  return (
    <Popup CloseIcon={Icons.Close} closeEffect={closePopup} buttons={buttons}>
      <h2 className="popup-title">Nouveau Goal</h2>
      <div className="popup-choose-type">
        <div className="popup-add-type">{kind}</div>
        <div value={kind} className="popup-add-selectfield">
          <button onClick={setKindEvent}>event</button>
          <button onClick={setKindPage}>page</button>
        </div>
      </div>
      <div className="popup-name">
        <Input value={name} onChange={updateName} required />
      </div>
    </Popup>
  );
};

export default AddGoalPopup;
