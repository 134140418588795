import * as React from "react";
import { deleteGoals } from "../../../services/api.js";
import { useGestionContext } from "../../common/contexts/getion-context.js";
import Popup from "../../common/popup.js";
import * as Icons from "../../icons";

const RemoveGoalPopup = ({ type, name }) => {
  const { setPopup, activeDomain, setActiveDomain } = useGestionContext();

  const submitForm = async (e) => {
    e.preventDefault();
    const body = {
      domains: [activeDomain.name],
      goals: [name],
    };
    try {
      await deleteGoals(body);
      setActiveDomain((activeDomain) => {
        const newGoals = activeDomain.goals.filter(
          (goal) => goal.name !== name
        );
        return { ...activeDomain, goals: newGoals };
      });
    } catch (e) {}
    setPopup(undefined);
  };

  const closePopup = () => {
    setPopup(undefined);
  };

  const buttons = [
    { message: "Annuler", action: closePopup },
    { message: "Supprimer", action: submitForm },
  ];

  return (
    <Popup CloseIcon={Icons.Close} closeEffect={closePopup} buttons={buttons}>
      <p className="popup-delete-text">
        Êtes vous sûr de vouloir supprimer{" "}
        {type.startsWith("event") ? "l'évènement" : "la page"}: <br />
        {name}
        <br /> de <br />{" "}
        <a className="popup-delete-text-domain" href={activeDomain.name}>
          {activeDomain.name}{" "}
        </a>{" "}
      </p>
    </Popup>
  );
};

export default RemoveGoalPopup;
