import * as React from "react";
import { deleteDomains, getDomains } from "../../../services/api.js";
import { useGestionContext } from "../../common/contexts/getion-context.js";
import Popup from "../../common/popup.js";
import * as Icons from "../../icons";

const RemoveDomainPopup = ({ setLoading }) => {
  const { setPopup, activeDomain, setActiveDomain, setDomains } =
    useGestionContext();

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    await deleteDomains([activeDomain.name]);
    const result = await getDomains();
    setDomains(result);
    setActiveDomain({});
    setLoading(false);
    setPopup(undefined);
  };

  const closePopup = () => {
    setPopup(undefined);
  };

  const buttons = [
    { message: "Annuler", action: closePopup },
    { message: "Supprimer", action: submitForm },
  ];

  return (
    <Popup CloseIcon={Icons.Close} closeEffect={closePopup} buttons={buttons}>
      <p className="popup-delete-text">
        Êtes vous sûr de vouloir supprimer <br />{" "}
        <a className="popup-delete-text-domain" href={activeDomain.name}>
          {activeDomain.name}{" "}
        </a>
        <br />
        {activeDomain.deleted ? "de la database" : undefined}{" "}
      </p>
    </Popup>
  );
};

export default RemoveDomainPopup;
