import * as React from "react";
import Input from "@valraiso-esf/esf-ui/es/input";
import classnames from "classnames";
import * as Icons from "../icons";

const Domains = ({ domains, getActiveDomain }) => {
  const [selected, setSelected] = React.useState("");
  const [search, setSearch] = React.useState("");

  const updateSearchValue = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const filteredDomains = domains.filter((elmt) =>
    elmt.name.toLowerCase().includes(search.toLowerCase())
  );

  const updateSelected = async (e) => {
    if (window.innerWidth <= 500) setSearch("");
    const index = filteredDomains.findIndex(
      (elmt) => elmt.name === e.target.value
    );
    await getActiveDomain(filteredDomains[index]);
    setSelected(e.target.value);
  };

  return (
    <div className="gestion-scrollbar" id="domains-gestion">
      <div className="gestion-scrollbar__search">
        <Input
          type="search"
          value={search}
          onChange={updateSearchValue}
          left={<Icons.Search className="gestion-scrolbar__icon" />}
        />
      </div>
      <div className="gestion-scrollbar__domains">
        {(search !== "" || window.innerWidth > 500) &&
          filteredDomains.map((elmt, index) => {
            const { name, deleted } = elmt;
            const deletedClass = classnames("gestion-scrollbar__button", {
              "gestion-scrollbar__button--deleted": deleted,
              "gestion-scrollbar__button--selected": selected === name,
            });

            return (
              <button
                className={deletedClass}
                key={name + index}
                value={name}
                onClick={updateSelected}
              >
                {name}
              </button>
            );
          })}
      </div>
      {window.innerWidth > 500 && (
        <div className="gestion-scrolbar__results">
          {filteredDomains.length} résultats
        </div>
      )}
    </div>
  );
};

export default Domains;
