import AddGoalPopup from "./goal/add-goal";
import RemoveGoalPopup from "./goal/remove-goal";
import RestoreGoalPopup from "./goal/restore-goal";
import RemoveDomainPopup from "./domain/remove-domain";
import RestoreDomainPopup from "./domain/restore-domain";
import AddSpecificityPopup from "./specificity/add-specificity";
import RemoveSpecificityPopup from "./specificity/remove-specificity";
import InfoSpecificityPopup from "./specificity/info-specificity";
import RemoveTokenPopup from "./token/remove-token";
import AddTokenPopup from "./token/add-token";
import ErrorPopup from "./error";
import IncomesSettings from "./incomes-parameters/index";
import MonitorankSettings from "./monitorank/index.js";
import { useGestionContext } from "../common/contexts/getion-context";
import IncomesContextProvider from "../common/contexts/incomes-context";
import MonitorankContextProvider from "../common/contexts/monitorank-context";

const Popups = ({ setLoading }) => {
  const { popup, activeDomain } = useGestionContext();
  const POPUPS = {
    addGoal: <AddGoalPopup type={popup && popup.data && popup.data.type} />,
    deleteGoal: (
      <RemoveGoalPopup
        type={popup && popup.data && popup.data.type}
        name={popup && popup.data && popup.data.name}
      />
    ),
    restoreGoal: (
      <RestoreGoalPopup
        type={popup && popup.data && popup.data.type}
        name={popup && popup.data && popup.data.name}
      />
    ),
    deleteDomain: <RemoveDomainPopup setLoading={setLoading} />,
    restoreDomain: <RestoreDomainPopup setLoading={setLoading} />,
    addSpecificity: <AddSpecificityPopup />,
    removeSpecificity: <RemoveSpecificityPopup />,
    infoSpecificity: <InfoSpecificityPopup popup={popup} />,
    removeToken: <RemoveTokenPopup popup={popup} />,
    createToken: <AddTokenPopup />,
    error: <ErrorPopup />,
    incomesSettings: (
      <IncomesContextProvider domain={activeDomain.name}>
        <IncomesSettings domain={activeDomain.name} />
      </IncomesContextProvider>
    ),
    monitorank: (
      <MonitorankContextProvider>
        <MonitorankSettings />
      </MonitorankContextProvider>
    ),
  };
  if (popup && popup.name && POPUPS[popup.name]) return POPUPS[popup.name];
  return undefined;
};

export default Popups;
