import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoaderBar from "@valraiso-esf/esf-ui/es/loader-bar";
import Menu from "./page-handler/menu";
import LoginForm from "./page-handler/login-form";
import Creation from "./creation";
import Deletion from "./deletion";
import Gestion from "./gestion";
import Error from "./page-handler/error";
import { useAppContext } from "./common/contexts/app-context";
import GestionContextProvider from "./common/contexts/getion-context";

const App = () => {
  const { loading, loggedIn } = useAppContext();

  return (
    <div className="app">
      {loading && (
        <div className="loader">
          <LoaderBar />
        </div>
      )}
      <BrowserRouter>
        {loggedIn && <Menu />}
        <Routes>
          <Route path="" element={<LoginForm />} />
          <Route path="/">
            <Route path="creation" element={<Creation />} />
            <Route path="deletion" element={<Deletion />} />
            <Route
              path="gestion"
              element={
                <GestionContextProvider>
                  <Gestion />
                </GestionContextProvider>
              }
            />
            <Route path="*" element={<Error />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
