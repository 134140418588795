import * as React from "react";
import Button from "@valraiso-esf/esf-ui/es/button";
import SelectMultiple from "../../common/select-multiple/select-multiple";
import Checkbox from "../../common/select-multiple/checkbox";
import Loaderbar from "@valraiso-esf/esf-ui/es/loader-bar";
import { getTokenPerms, updateTokenPerms } from "../../../services/api";

const Permissions = ({ setPermissions, domains, token }) => {
  const [value, setValue] = React.useState();
  const [givePerm, setGivePerm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const exec = async () => {
      const response = await getTokenPerms(token);
      if (response.permLevel === 1) {
        setGivePerm(true);
      }
      const domainsArray = domains.map((elmt) => {
        if (response.res.includes(elmt.name))
          return { name: elmt.name, value: elmt.name, selected: true };
        return { name: elmt.name, value: elmt.name, selected: false };
      });
      setValue(domainsArray);
    };
    exec();
  }, [domains, token]);

  const updatePermissions = async () => {
    setLoading(true);
    const body = {
      domains: value
        .map((elmt) => {
          if (!elmt.selected) return null;
          return elmt.name;
        })
        .filter((elmt) => elmt),
      token,
      permLevel: givePerm ? 1 : 0,
    };
    await updateTokenPerms(body);
    setLoading(false);
    setPermissions(undefined);
  };

  const results = value ? value.filter((elmt) => elmt.selected).length : 0;

  return (
    <div className="permissions__back">
      <div className="permissions__zone">
        {(!value || loading) && <Loaderbar />}
        {value && (
          <>
            <SelectMultiple
              value={value}
              onValueChange={setValue}
              className="permissions__select-multiple"
            />
            <div className="permissions__bottom">
              <span>
                {results}{" "}
                {results > 1 ? "éléments séléctionnés" : "élément sélectionné"}
              </span>
              <Checkbox
                label="Autoriser les regroupements géographiques"
                onChange={() => setGivePerm(!givePerm)}
                checked={givePerm}
              />
              <div className="permissions__buttons">
                <Button onClick={() => setPermissions(undefined)}>
                  Annuler
                </Button>
                <Button onClick={() => updatePermissions()}>Sauvegarder</Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Permissions;
