import * as React from "react";
import Tooltip from "@valraiso-esf/esf-ui/es/tooltip";
import Button from "@valraiso-esf/esf-ui/es/button";
import Domains from "./domains";
import Goals from "./goals/index";
import Specificities from "./specificities";
import {
  getDomains,
  getGoals,
  getLink,
  refreshData,
  getSpecificities,
} from "../../services/api";
import * as Icons from "../icons";
import Popups from "../popups";
import Dropdown from "../common/dropdown";
import { Navigate } from "react-router-dom";
import Users from "./users/index";
import { useAppContext } from "../common/contexts/app-context";
import useSetPage from "../../hooks/use-set-page";
import { useGestionContext } from "../common/contexts/getion-context";

const Gestion = () => {
  const { setLoading, loggedIn, setSelectedPage } = useAppContext();
  const {
    lastSync,
    domains,
    setDomains,
    activeDomain,
    setActiveDomain,
    setPopup,
  } = useGestionContext();

  const getActiveDomain = async ({ name, deleted }) => {
    const [goals, { link }, specificities] = await Promise.all([
      getGoals(name),
      getLink(name),
      getSpecificities(name),
    ]);
    setActiveDomain({ name, goals, link: link, deleted, specificities });
  };

  useSetPage(setSelectedPage, "/gestion");

  if (!loggedIn) {
    return <Navigate to="/" />;
  }

  const deleteDomain = async () => {
    if (!activeDomain.name) return;
    setPopup({ name: "deleteDomain" });
  };

  const restore = async () => {
    if (!activeDomain.deleted) return;
    setPopup({ name: "restoreDomain" });
  };

  const refresh = async () => {
    setLoading(true);
    await refreshData();
    const result = await getDomains();
    setLoading(false);
    setDomains(result);
    setActiveDomain({});
  };

  const defaultDomainOption = [
    {
      Icon: Icons.Delete,
      label: "Supprimer",
      callback: deleteDomain,
    },
    {
      Icon: Icons.Settings,
      label: "Paramétrer les revenus",
      callback: () => setPopup({ name: "incomesSettings" }),
    },
  ];
  const deletedDomainOption = [
    {
      Icon: Icons.Restore,
      label: "Restaurer",
      callback: restore,
    },
  ];

  const domainOptions = activeDomain?.deleted
    ? deletedDomainOption.concat(defaultDomainOption)
    : defaultDomainOption;

  return (
    <div className="gestion__zone">
      <div className="gestion">
        <Domains domains={domains} getActiveDomain={getActiveDomain} />
        <Popups setLoading={setLoading} />
        <div className="gestion__infos">
          <div className="gestion__update">
            <Button className="gestion__update__button" onClick={refresh}>
              <Icons.Sync className="gestion__update__button__icon" />
              Mettre à jour
            </Button>
            <p>Dernière mise à jour le : {lastSync}</p>
          </div>
          {activeDomain.name ? (
            <>
              <div className="gestion__title">
                <Tooltip title="Accéder au site" position="bottom">
                  <a href={`https://${activeDomain.name}`}>
                    {activeDomain.name}
                  </a>
                </Tooltip>
                <Dropdown
                  className="gestion__parameters"
                  options={domainOptions}
                />
              </div>
              <Goals />
              <Specificities />
            </>
          ) : (
            <div className="gestion__without-domain">
              <span>Veuillez sélectionner un domaine</span>
            </div>
          )}
        </div>
      </div>
      <Users />
      <Button
        onClick={() => setPopup({ name: "monitorank" })}
        className="monitorank__down__button"
        id="keywords-gestion"
      >
        Gestion des mots-clés Monitorank
      </Button>
    </div>
  );
};

export default Gestion;
