import Button from "@valraiso-esf/esf-ui/es/button";
import * as React from "react";
import * as Icons from "../../icons";
import Popup from "../../common/popup";
import IncomeType from "./income-type";
import UpdateIncomeType from "./update";
import { useGestionContext } from "../../common/contexts/getion-context";
import { useIncomesContext } from "../../common/contexts/incomes-context";

const IncomesSettings = ({ domain }) => {
  const { setPopup } = useGestionContext();
  const { settings, update, setUpdate } = useIncomesContext();

  return (
    <Popup
      CloseIcon={Icons.Close}
      closeEffect={() => setPopup(undefined)}
      className="incomes-settings__popup"
    >
      {settings && !update && (
        <div className="incomes-settings">
          <b>Paramètres des revenus</b>
          {settings.allTypes.map((elmt) => {
            return (
              <IncomeType
                key={elmt.libelle + elmt.orderTable}
                data={elmt}
                actual={elmt.id === settings.domainType}
                button={
                  <Button color="secondary" onClick={() => setUpdate(elmt)}>
                    Modifier
                  </Button>
                }
                exist={true}
                domain={domain}
              />
            );
          })}
          <IncomeType
            data={{
              id: 0,
              orderTable: [],
              tableRow: ["id"],
              libelle: "Nouveau",
            }}
            actual={false}
            button={
              <Button
                color="secondary"
                onClick={() =>
                  setUpdate({
                    id: 0,
                    orderTable: [],
                    tableRow: [],
                    libelle: "Nouveau",
                  })
                }
              >
                Ajouter un type de revenus
              </Button>
            }
            exist={false}
          />
        </div>
      )}
      {settings && update && <UpdateIncomeType />}
    </Popup>
  );
};

export default IncomesSettings;
