import * as React from "react";
import classnames from "classnames";

const CopyButton = ({ value, className }) => {
  const [copied, setCopied] = React.useState(false);
  const classes = classnames("copied-button", className);

  const copyToClipboard = async () => {
    const tokenUrl = `https://statistiques-plausible.valraiso.net/client?token=${value}`
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(tokenUrl);
    } else {
      document.execCommand("copy", true, tokenUrl);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <button className={classes} onClick={copyToClipboard}>
      {copied ? "Copié!" : "Copier"}
    </button>
  );
};

export default CopyButton;
