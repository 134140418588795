const events = [
  "Outbound Link: Click",
  "File Download",
  "404 error",
  "File Download",
  "Affiliation",
  "Search",
  "Contact-us",
  "MseM: View item",
  "MseM: Add to cart",
  "MseM: Begin checkout",
  "MseM: Checkout process",
  "MseM: Payment success",
  "MseM: Payment failure",
];

export const DEFAULT_EVENTS = events.join("\n");

const pages = ["/contact"];

export const DEFAULT_PAGES = pages.join("\n");
