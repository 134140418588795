import * as React from "react";
import {
  getGoals,
  getLink,
  removeSpecificity,
  getSpecificities,
} from "../../../services/api.js";
import { useGestionContext } from "../../common/contexts/getion-context.js";
import Popup from "../../common/popup.js";
import * as Icons from "../../icons";

const RemoveSpecificityPopup = () => {
  const { setPopup, activeDomain, setActiveDomain } = useGestionContext();
  const [kind, setKind] = React.useState(activeDomain.specificities[0].kind);

  const updateKind = (e) => {
    setKind(e.currentTarget.value);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const name = activeDomain.specificities.filter(
      (spe) => spe.kind === kind
    )[0].name;
    const body = {
      name,
      kind,
      domain: activeDomain.name,
    };
    await removeSpecificity(body);
    const [goals, link, specificities] = await Promise.all([
      getGoals(activeDomain.name),
      getLink(activeDomain.name),
      getSpecificities(activeDomain.name),
    ]);
    const domainName = activeDomain.name;
    setActiveDomain({
      name: domainName,
      goals,
      link: link.link,
      deleted: activeDomain.deleted,
      specificities,
    });
    setPopup(undefined);
  };

  const closePopup = () => {
    setPopup(undefined);
  };

  const buttons = [
    { message: "Annuler", action: closePopup },
    { message: "Supprimer", action: submitForm },
  ];

  return (
    <Popup CloseIcon={Icons.Close} closeEffect={closePopup} buttons={buttons}>
      <h2 className="popup-title">Supprimer une spécificité</h2>
      <div className="popup-choose-type">
        <div className="popup-add-type">{kind}</div>
        <div value={kind} className="popup-add-selectfield">
          {activeDomain.specificities &&
            activeDomain.specificities.map((spe) => {
              return (
                <button
                  key={spe.name + "delete"}
                  onClick={updateKind}
                  value={spe.kind}
                >
                  {spe.kind}
                </button>
              );
            })}
        </div>
        <p className="popup-choosed-type">
          {
            activeDomain.specificities.filter((spe) => spe.kind === kind)[0]
              .name
          }
        </p>
      </div>
    </Popup>
  );
};

export default RemoveSpecificityPopup;
