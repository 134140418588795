import * as React from "react";
import { useGestionContext } from "../../common/contexts/getion-context.js";
import GoalList from "./goal-list.js";

const Goals = () => {
  const { activeDomain, setPopup } = useGestionContext();
  const { goals = [] } = activeDomain;

  const { event: events, page: pages } = goals.reduce(
    (acc, goal) => {
      return { ...acc, [goal.kind]: [...acc[goal.kind], goal] };
    },
    {
      event: [],
      page: [],
    }
  );

  const openPopupAddEvent = () => {
    if (activeDomain === "" || activeDomain.deleted) return;
    setPopup({ name: "addGoal", data: { type: "event" } });
  };

  const openPopupAddPage = () => {
    if (activeDomain === "" || activeDomain.deleted) return;
    setPopup({ name: "addGoal", data: { type: "page" } });
  };

  return (
    <div className="gestion-goals">
      <GoalList
        className="gestion-goals__event"
        kind="event"
        goals={events}
        addCallback={openPopupAddEvent}
      />
      <GoalList
        className="gestion-goals__page"
        kind="page"
        goals={pages}
        addCallback={openPopupAddPage}
      />
    </div>
  );
};

export default Goals;
