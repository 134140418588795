import * as React from "react";
import classnames from "classnames";
import Switch from "@valraiso-esf/esf-ui/es/switch";
import { updateDomainIncomeSettings } from "../../../services/api";
import { useIncomesContext } from "../../common/contexts/incomes-context";

const IncomeType = ({ data, actual, button, exist, domain }) => {
  const { settings, setSettings } = useIncomesContext();

  const classes = classnames("incomes-settings__type", {
    "incomes-settings__type--choosen": actual,
    "incomes-settings__type--disabled": !exist,
  });

  const updateDomainType = async () => {
    if (data.id === 0) return;
    if (actual) {
      setSettings({ ...settings, domainType: 0 });
      updateDomainIncomeSettings({ id: 0, domain });
      return;
    }
    setSettings({ ...settings, domainType: data.id });
    updateDomainIncomeSettings({ id: data.id, domain });
  };

  return (
    <div className={classes}>
      <div className="incomes-settings__type__text">
        <b>{data.libelle}</b>
        <span>{data.orderTable[0] ? data.orderTable.join(" / ") : "..."}</span>
      </div>
      <div className="incomes-settings__type__buttons">
        <Switch
          checked={actual}
          onChange={updateDomainType}
          disabled={data.id === 0}
        >
          Activer sur le domaine
        </Switch>
        {button}
      </div>
    </div>
  );
};

export default IncomeType;
