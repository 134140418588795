import * as React from "react";
import Input from "@valraiso-esf/esf-ui/es/input";
import * as Icons from "../../icons";
import Popup from "../../common/popup.js";
import { addToken } from "../../../services/api.js";
import { useGestionContext } from "../../common/contexts/getion-context";

const AddTokenPopup = () => {
  const { setPopup } = useGestionContext();
  const [name, setName] = React.useState("");

  const updateName = (e) => {
    setName(e.target.value);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (name === "") return;
    await addToken({ name });
    setPopup(undefined);
  };

  const closePopup = () => {
    setPopup(undefined);
  };

  const buttons = [
    { message: "Annuler", action: closePopup },
    { message: "Ajouter", action: submitForm },
  ];

  return (
    <Popup CloseIcon={Icons.Close} closeEffect={closePopup} buttons={buttons}>
      <h2 className="popup-title">Nouveau Token</h2>
      <div className="popup-name">
        <Input
          value={name}
          onChange={updateName}
          placeholder="Libellé"
          required
        />
      </div>
    </Popup>
  );
};

export default AddTokenPopup;
