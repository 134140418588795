import * as React from "react";
import Tooltip from "@valraiso-esf/esf-ui/es/tooltip";
import * as Icons from "../../icons";
import Goal from "./goal";
import { useGestionContext } from "../../common/contexts/getion-context";

const GoalList = ({ className, kind, goals, addCallback }) => {
  const { activeDomain } = useGestionContext();

  return (
    <div className={className}>
      <h3 className="gestion-goals__title">
        {kind === "event" ? "Events" : "Pages"}
      </h3>
      <div className="gestion-goals__add">
        <Tooltip
          title={kind === "event" ? "Ajouter un évènement" : "Ajouter une page"}
          position="left"
        >
          <Icons.Add
            onClick={addCallback}
            className="gestion-goals__add__icon"
          />
        </Tooltip>
      </div>
      <div className="gestion-goals__list">
        {goals.map((goal) => {
          return <Goal key={activeDomain.name + goal.name} goal={goal} />;
        })}
      </div>
    </div>
  );
};

export default GoalList;
