import * as React from "react";
import classnames from "classnames";
import Button from "@valraiso-esf/esf-ui/es/button";

const Popup = ({ className, children, closeEffect, CloseIcon, buttons }) => {
  const classes = classnames("generic-popup__background", className);
  return (
    <div className={classes}>
      <div className="generic-popup__content">
        <button onClick={closeEffect} className="generic-popup__close-button">
          <CloseIcon className="generic-popup__close-icon" />
        </button>
        {children}
        {buttons && (
          <div className="generic-popup__buttons">
            {buttons.map((button, index) => {
              return (
                <Button
                  key={
                    String(index) +
                    String(button.message) +
                    String(button.action)
                  }
                  onClick={button.action}
                >
                  {button.message}
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;
