import * as React from "react";
import { getIncomeSettings } from "../../../services/api";

const IncomesContext = React.createContext();

export const useIncomesContext = () => {
  const context = React.useContext(IncomesContext);
  if (!context)
    throw new Error(
      `useIncomesContext must be used within a IncomesContextProvider`
    );
  return context;
};

const IncomesContextProvider = (props) => {
  const { domain } = props ? props : {};
  const [settings, setSettings] = React.useState();
  const [update, setUpdate] = React.useState();

  React.useEffect(() => {
    const exec = async () => {
      const response = await getIncomeSettings(domain);
      setSettings(response);
    };
    exec();
  }, [domain]);

  return (
    <IncomesContext.Provider
      value={{ settings, setSettings, update, setUpdate }}
      {...props}
    />
  );
};

export default IncomesContextProvider;
