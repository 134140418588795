import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/app";

import "@valraiso-esf/esf-ui/es/theme.css";
import "./styles/index.css";
import AppContextProvider from "./components/common/contexts/app-context";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </React.StrictMode>
);
