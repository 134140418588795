export const convertSearchConsoleToSelectMultiple = (searchConsoles) => {
  return searchConsoles.map((searchConsole) => {
    return {
      name: searchConsole,
      value: searchConsole,
      selected: false,
    };
  });
};

export const convertKeywordToSelectMultiple = (keywords) => {
  return keywords.map((keyword) => {
    return {
      name: `${keyword.lang} - ${keyword.keyword}`,
      value: `${keyword.lang} - ${keyword.keyword}`,
      keyword: keyword.keyword,
      lang: keyword.lang,
      selected: false,
    };
  });
};
