import * as React from "react";

const Icon = ({ width = 24, height = 24, ...props }) => (
  <svg viewBox="0 0 24 24" width={width} height={height} {...props}>
    {props.children}
  </svg>
);

export const Add = (props) => (
  <Icon {...props}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </Icon>
);

export const Delete = (props) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      d="M8.252 1.614C8.652 1.219 9.19 1 9.75 1h4.5c.56 0 1.099.22 1.498.614.4.395.627.933.627 1.497v2.333H21a1 1 0 1 1 0 2h-.195l-.908 12.563a3.211 3.211 0 0 1-1.031 2.133 3.266 3.266 0 0 1-2.21.86H7.344a3.266 3.266 0 0 1-2.21-.86 3.211 3.211 0 0 1-1.032-2.132L3.195 7.444H3a1 1 0 1 1 0-2h4.625V3.111c0-.564.227-1.102.627-1.497ZM5.2 7.444l.898 12.42c.022.304.16.592.39.804.23.212.537.332.857.332h9.31c.32 0 .626-.12.857-.332.23-.212.368-.5.39-.804l.898-12.42H5.2Zm9.175-2h-4.75V3.111c0-.025.01-.052.032-.074A.132.132 0 0 1 9.75 3h4.5c.037 0 .07.015.093.037a.104.104 0 0 1 .032.074v2.333ZM9.75 9.89a1 1 0 0 1 1 1v6.667a1 1 0 0 1-2 0v-6.667a1 1 0 0 1 1-1Zm4.5 0a1 1 0 0 1 1 1v6.667a1 1 0 0 1-2 0v-6.667a1 1 0 0 1 1-1Z"
    />
  </Icon>
);

export const Parameters = (props) => (
  <Icon {...props}>
    <path d="M3.58579 10.5858C3.96086 10.2107 4.46957 10 5 10C5.53043 10 6.03914 10.2107 6.41421 10.5858C6.78929 10.9609 7 11.4696 7 12C7 12.5304 6.78929 13.0391 6.41421 13.4142C6.03914 13.7893 5.53043 14 5 14C4.46957 14 3.96086 13.7893 3.58579 13.4142C3.21071 13.0391 3 12.5304 3 12C3 11.4696 3.21071 10.9609 3.58579 10.5858ZM10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858ZM17.5858 10.5858C17.9609 10.2107 18.4696 10 19 10C19.5304 10 20.0391 10.2107 20.4142 10.5858C20.7893 10.9609 21 11.4696 21 12C21 12.5304 20.7893 13.0391 20.4142 13.4142C20.0391 13.7893 19.5304 14 19 14C18.4696 14 17.9609 13.7893 17.5858 13.4142C17.2107 13.0391 17 12.5304 17 12C17 11.4696 17.2107 10.9609 17.5858 10.5858Z" />
  </Icon>
);

export const Restore = (props) => (
  <Icon {...props}>
    <path d="M15.3 16.7L16.7 15.3L13 11.6V7H11V12.4L15.3 16.7ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z" />
  </Icon>
);

export const Search = (props) => (
  <Icon {...props}>
    <path d="M10 4a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm-5.66.34a8 8 0 0 1 11.98 10.57l5.39 5.38a1 1 0 0 1-1.42 1.42l-5.38-5.39A8 8 0 0 1 4.34 4.34Z" />
  </Icon>
);

export const Sync = (props) => (
  <Icon {...props}>
    <path d="M15.375 19.25q-.525.25-.95-.038-.425-.287-.425-.937 0-.25.163-.487.162-.238.412-.363 1.575-.75 2.5-2.225T18 11.95q0-1.125-.425-2.188Q17.15 8.7 16.25 7.8L16 7.55V9q0 .425-.287.712Q15.425 10 15 10t-.712-.288Q14 9.425 14 9V5q0-.425.288-.713Q14.575 4 15 4h4q.425 0 .712.287Q20 4.575 20 5t-.288.713Q19.425 6 19 6h-1.75l.4.35q1.225 1.225 1.788 2.662Q20 10.45 20 11.95q0 2.4-1.25 4.362-1.25 1.963-3.375 2.938ZM5 20q-.425 0-.713-.288Q4 19.425 4 19t.287-.712Q4.575 18 5 18h1.75l-.4-.35q-1.225-1.225-1.788-2.662Q4 13.55 4 12.05q0-2.4 1.25-4.363Q6.5 5.725 8.625 4.75q.525-.25.95.037.425.288.425.938 0 .25-.162.487-.163.238-.413.363-1.575.75-2.5 2.225T6 12.05q0 1.125.425 2.187Q6.85 15.3 7.75 16.2l.25.25V15q0-.425.288-.713Q8.575 14 9 14t.713.287Q10 14.575 10 15v4q0 .425-.287.712Q9.425 20 9 20Z" />
  </Icon>
);

export const Close = (props) => (
  <Icon {...props}>
    <path d="M8.25 16.8L12 13.05L15.75 16.8L16.8 15.75L13.05 12L16.8 8.25L15.75 7.2L12 10.95L8.25 7.2L7.2 8.25L10.95 12L7.2 15.75L8.25 16.8ZM12 22C10.6333 22 9.34167 21.7375 8.125 21.2125C6.90833 20.6875 5.84583 19.9708 4.9375 19.0625C4.02917 18.1542 3.3125 17.0917 2.7875 15.875C2.2625 14.6583 2 13.3667 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.02917 5.825 4.9375 4.925C5.84583 4.025 6.90833 3.3125 8.125 2.7875C9.34167 2.2625 10.6333 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3667 21.7375 14.6583 21.2125 15.875C20.6875 17.0917 19.975 18.1542 19.075 19.0625C18.175 19.9708 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20.5C14.3667 20.5 16.375 19.6708 18.025 18.0125C19.675 16.3542 20.5 14.35 20.5 12C20.5 9.63333 19.675 7.625 18.025 5.975C16.375 4.325 14.3667 3.5 12 3.5C9.65 3.5 7.64583 4.325 5.9875 5.975C4.32917 7.625 3.5 9.63333 3.5 12C3.5 14.35 4.32917 16.3542 5.9875 18.0125C7.64583 19.6708 9.65 20.5 12 20.5Z" />
  </Icon>
);

export const Copy = (props) => (
  <Icon {...props}>
    <path d="M5.075 21.225q-.95 0-1.612-.675-.663-.675-.663-1.6V5.1q0-.95.663-1.613.662-.662 1.612-.662h3.95q.3-.9 1.138-1.475Q11 .775 12 .775q1.025 0 1.863.575.837.575 1.137 1.475h3.925q.95 0 1.613.662.662.663.662 1.613v13.85q0 .925-.662 1.6-.663.675-1.613.675Zm0-2.275h13.85V5.1h-1.95v3.075h-9.95V5.1h-1.95v13.85ZM12 5q.425 0 .713-.288Q13 4.425 13 4t-.287-.713Q12.425 3 12 3t-.712.287Q11 3.575 11 4t.288.712Q11.575 5 12 5Z" />
  </Icon>
);

export const AddSign = (props) => (
  <Icon {...props}>
    <path d="M11 19v-6H5v-2h6V5h2v6h6v2h-6v6Z" />
  </Icon>
);

export const Settings = (props) => (
  <Icon {...props}>
    <path d="m9.25 22-.4-3.2q-.325-.125-.612-.3-.288-.175-.563-.375L4.7 19.375l-2.75-4.75 2.575-1.95Q4.5 12.5 4.5 12.337v-.675q0-.162.025-.337L1.95 9.375l2.75-4.75 2.975 1.25q.275-.2.575-.375.3-.175.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3.287.175.562.375l2.975-1.25 2.75 4.75-2.575 1.95q.025.175.025.337v.675q0 .163-.05.338l2.575 1.95-2.75 4.75-2.95-1.25q-.275.2-.575.375-.3.175-.6.3l-.4 3.2Zm2.8-6.5q1.45 0 2.475-1.025Q15.55 13.45 15.55 12q0-1.45-1.025-2.475Q13.5 8.5 12.05 8.5q-1.475 0-2.488 1.025Q8.55 10.55 8.55 12q0 1.45 1.012 2.475Q10.575 15.5 12.05 15.5Zm0-2q-.625 0-1.062-.438-.438-.437-.438-1.062t.438-1.062q.437-.438 1.062-.438t1.063.438q.437.437.437 1.062t-.437 1.062q-.438.438-1.063.438ZM12 12Zm-1 8h1.975l.35-2.65q.775-.2 1.438-.588.662-.387 1.212-.937l2.475 1.025.975-1.7-2.15-1.625q.125-.35.175-.738.05-.387.05-.787t-.05-.788q-.05-.387-.175-.737l2.15-1.625-.975-1.7-2.475 1.05q-.55-.575-1.212-.963-.663-.387-1.438-.587L13 4h-1.975l-.35 2.65q-.775.2-1.437.587-.663.388-1.213.938L5.55 7.15l-.975 1.7 2.15 1.6q-.125.375-.175.75-.05.375-.05.8 0 .4.05.775t.175.75l-2.15 1.625.975 1.7 2.475-1.05q.55.575 1.213.962.662.388 1.437.588Z" />
  </Icon>
);

export const Check = (props) => (
  <Icon {...props}>
    <polyline points="12 3 7 12 3 9"></polyline>
  </Icon>
);

export const DownExpand = (props) => (
  <Icon {...props}>
    <path d="m12 19-6-6 1.4-1.4 4.6 4.575 4.6-4.575L18 13Zm0-6L6 7l1.4-1.4 4.6 4.575L16.6 5.6 18 7Z" />
  </Icon>
);
