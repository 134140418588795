import * as React from "react";
import { updateSettings } from "../../../utils/order-types";
import { useIncomesContext } from "../../common/contexts/incomes-context";
import Grid from "./grid";

const UpdateIncomeType = () => {
  const {
    update: data,
    settings,
    setSettings,
    setUpdate,
  } = useIncomesContext();

  return (
    <div className="incomes-settings">
      <Grid
        options={data}
        columns={[
          { name: "orderTable", title: "Nom de la table" },
          { name: "tableRow", title: "Column de l'orderId" },
        ]}
        onValidation={(e) =>
          updateSettings(
            { ...e },
            e.libelle,
            settings,
            setUpdate,
            data,
            setSettings
          )
        }
      />
    </div>
  );
};

export default UpdateIncomeType;
