import {
  deleteDomains,
  createDomains,
  createGoals,
  createLinks,
} from "./api.js";

export const handleInputCreation = async (
  eventsValue,
  pagesValue,
  eventsChecked,
  pagesChecked,
  linksCheck,
  addCheck,
  domainsValue,
  setLogs,
  segmentationId,
  orderTypeId
) => {
  const domains = [...new Set(domainsValue.split("\n"))].filter(Boolean);
  const events = [...new Set(eventsValue.split("\n"))].filter(Boolean);
  const pages = [...new Set(pagesValue.split("\n"))].filter(Boolean);

  if (addCheck) {
    const creationResponse = await createDomains(
      domains,
      segmentationId,
      orderTypeId
    );
    setLogs((logs) => [...logs, ...creationResponse]);
  }
  if (eventsChecked) {
    const eventsResponse = await createGoals(domains, events, "event");
    setLogs((logs) => [...logs, ...eventsResponse]);
  }
  if (pagesChecked) {
    const pagesResponse = await createGoals(domains, pages, "page");
    setLogs((logs) => [...logs, ...pagesResponse]);
  }
  if (linksCheck) {
    const linksResponse = await createLinks(domains);
    setLogs((logs) => [...logs, ...linksResponse]);
  }
};

export const handleInputDeletion = async (domainsValue, setLogs) => {
  const domains = [...new Set(domainsValue.split("\n"))].filter(Boolean);

  const deletionResponse = await deleteDomains(domains);
  setLogs((logs) => [...logs, ...deletionResponse]);
};
