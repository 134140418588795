import * as React from "react";
import Popup from "../../common/popup.js";
import * as Icons from "../../icons";
import { removeToken } from "../../../services/api.js";
import { useGestionContext } from "../../common/contexts/getion-context.js";

const RemoveTokenPopup = ({ popup }) => {
  const { setPopup } = useGestionContext();

  const submitForm = async (e) => {
    e.preventDefault();
    const token = popup.data.token;
    await removeToken({ token });
    setPopup(undefined);
  };

  const closePopup = () => {
    setPopup(undefined);
  };

  const buttons = [
    { message: "Annuler", action: closePopup },
    { message: "Supprimer", action: submitForm },
  ];

  return (
    <Popup CloseIcon={Icons.Close} closeEffect={closePopup} buttons={buttons}>
      <p className="popup-delete-text">
        Êtes vous sûr de vouloir supprimer le token
      </p>
    </Popup>
  );
};

export default RemoveTokenPopup;
