import * as React from "react";
import classnames from "classnames";
import Checkbox from "./checkbox";

const SelectMultiple = ({
  className,
  value = [],
  onValueChange,
  inputProps,
  label,
}) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [allSelected, setAllSelected] = React.useState(false);

  const updateSearchValue = (e) => {
    setSearchValue(e.target.value);
    setAllSelected(false);
  };

  const classes = classnames("select-multiple", {
    [className]: className,
  });

  const updateList = (e) => {
    const newList = value.map((element) => {
      if (element.name === e.target.value) {
        return {
          ...element,
          selected: !element.selected,
        };
      }
      return element;
    });
    onValueChange(newList);
  };

  const searchList = value.filter((element) =>
    element.name.includes(searchValue)
  );

  const updateAllSelected = () => {
    const newList = searchList.map((element) => {
      return {
        ...element,
        selected: !allSelected,
      };
    });
    const toAdd = value.map((elmt) => {
      for (const obj of newList) {
        if (obj.value === elmt.value) {
          return obj;
        }
      }
      return elmt;
    });
    onValueChange(toAdd);
    setAllSelected(!allSelected);
  };

  return (
    <div className={classes} {...inputProps}>
      <div className="select-multiple__options">
        {label && <span className="select-multiple__title">{label}</span>}
        <input
          type="text"
          value={searchValue}
          onChange={updateSearchValue}
          className="select-multiple__input"
        />
        <Checkbox
          label="Select all"
          checked={allSelected}
          onChange={updateAllSelected}
        />
        <div className="select-multiple__options">
          {searchList.map((element, index) => {
            return (
              <label
                key={element.name + index}
                className="select-multiple__option"
              >
                <Checkbox
                  label={element.name}
                  checked={element.selected}
                  value={element.name}
                  onChange={updateList}
                />
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectMultiple;
