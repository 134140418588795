import * as React from "react";
import {
  getMonitorankKeywords,
  getSearchConsoleKeywords,
} from "../../../services/api";

const MonitorankContext = React.createContext();

export const useMonitorankContext = () => {
  const context = React.useContext(MonitorankContext);
  if (!context)
    throw new Error(
      `useMonitorankContext must be used within a MonitorankContextProvider`
    );
  return context;
};

const MonitorankContextProvider = (props) => {
  const [action, setAction] = React.useState();
  const [keywords, setKeywords] = React.useState();
  const [searchConsoles, setSearchConsoles] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const exec = async () => {
      setIsLoading(true);
      const [allKeywords, { searchConsoles: allSearchConsoles }] =
        await Promise.all([
          getMonitorankKeywords(),
          getSearchConsoleKeywords(),
        ]);
      setKeywords(allKeywords);
      setSearchConsoles(allSearchConsoles);
      setIsLoading(false);
    };
    exec();
  }, []);

  return (
    <MonitorankContext.Provider
      value={{ action, setAction, keywords, searchConsoles, isLoading }}
      {...props}
    />
  );
};

export default MonitorankContextProvider;
