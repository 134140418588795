import * as React from "react";
import classnames from "classnames";
import * as Icons from "../icons";

const Dropdown = ({ className, classNameButton, options = [], children }) => {
  const ref = React.useRef();
  const [opened, setOpened] = React.useState(false);

  const onClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setOpened(false);
    }
  };

  const toggleDropdown = () => {
    setOpened(!opened);
  };

  React.useEffect(() => {
    document.body.addEventListener("click", onClickOutside);
    return () => {
      document.body.removeEventListener("click", onClickOutside);
    };
  }, []);

  const classes = classnames("dropdown", {
    [className]: className,
  });

  const buttonClasses = classnames("dropdown__toggle", {
    [classNameButton]: classNameButton,
  });

  return (
    <div ref={ref} className={classes}>
      <button className={buttonClasses} onClick={toggleDropdown}>
        <Icons.Parameters className="dropdown__toggle-icon" />
      </button>
      {children}
      {opened && (
        <div className="dropdown__options">
          {options.map((option, i) => {
            const { Icon, label, callback } = option;
            return (
              <button key={i} className="dropdown__option" onClick={callback}>
                {Icon && <Icon className="dropdown__option-icon" />}
                <span className="dropdown__option-label">{label}</span>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
