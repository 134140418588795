import * as React from "react";
import classnames from "classnames";
import { Link, Outlet } from "react-router-dom";

const Tabs = ({ className, elements = [], selected, setSelected }) => {
  const [coords, setCoords] = React.useState({ x: 0, y: 0 });
  const [isRippling, setIsRippling] = React.useState(false);

  const classes = classnames("tabs", className);

  React.useEffect(() => {
    if (coords.x !== 0 && coords.y !== 0) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  const onClickInside = (e) => {
    setSelected(e.target.value);
    const rect = e.target.getBoundingClientRect();
    setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  React.useEffect(() => {}, [isRippling]);

  return (
    <div className={classes}>
      {elements.map((element) => {
        const selectedClasses = classnames("tabs__button", {
          "tabs__button--selected": element.page === selected,
        });
        return (
          <Link to={element.page} key={element.title}>
            <button
              value={element.page}
              className={selectedClasses}
              onClick={onClickInside}
            >
              {isRippling && selected === element.page && (
                <span
                  className="ripple"
                  style={{
                    left: coords.x,
                    top: coords.y,
                  }}
                />
              )}
              {element.title}
            </button>
          </Link>
        );
      })}
      <Outlet />
    </div>
  );
};

export default Tabs;
