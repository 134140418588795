import * as React from "react";
import {
  restoreDomain,
  getDomains,
  getGoals,
  getLink,
  getSpecificities,
} from "../../../services/api.js";
import { useGestionContext } from "../../common/contexts/getion-context.js";
import Popup from "../../common/popup.js";
import * as Icons from "../../icons";

const RestoreDomainPopup = ({ setLoading }) => {
  const { setPopup, activeDomain, setActiveDomain, setDomains } =
    useGestionContext();
  const submitForm = async (e) => {
    if (!activeDomain.deleted) return;
    setLoading(true);
    setPopup(undefined);
    const response = await restoreDomain(activeDomain.name);
    try {
      if (response.status !== "error") {
        const result = await getDomains();
        setDomains(result);
        const [goals, link, specificities] = await Promise.all([
          getGoals(activeDomain.name),
          getLink(activeDomain.name),
          getSpecificities(activeDomain.name),
        ]);
        setActiveDomain({
          name: activeDomain.name,
          goals,
          link: link.link,
          deleted: 0,
          specificities,
        });
      }
    } catch (e) {
      setPopup({ name: "error" });
    }
    setLoading(false);
  };

  const closePopup = () => {
    setPopup(undefined);
  };

  const buttons = [
    { message: "Annuler", action: closePopup },
    { message: "Restaurer", action: submitForm },
  ];

  return (
    <Popup CloseIcon={Icons.Close} closeEffect={closePopup} buttons={buttons}>
      <p className="popup-delete-text">
        Êtes vous sûr de vouloir restaurer <br />
        <a className="popup-delete-text-domain" href={activeDomain.name}>
          {activeDomain.name}
        </a>
      </p>
    </Popup>
  );
};

export default RestoreDomainPopup;
