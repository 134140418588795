import * as React from "react";
import * as Icons from "../../icons";

const Specificity = ({ spe, callback, removeCallback }) => {
  return (
    <div
      className="gestion-spe__specificity"
      onClick={() => callback(spe.kind, spe.name)}
    >
      {spe.kind}
      <button className="gestion-spe__specificity__remove">
        <Icons.Delete
          className="gestion-spe__specificity__remove__icon"
          onClick={removeCallback}
        />
      </button>
      <div className="gestion-spe__specificity__name">{spe.name}</div>
    </div>
  );
};

export default Specificity;
