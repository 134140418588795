import * as React from "react";
import { login } from "../../../services/api";

const AppContext = React.createContext();

export const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (!context)
    throw new Error(`useAppContext must be used within a AppContextProvider`);
  return context;
};

const AppContextProvider = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [domainsValue, setDomainsValue] = React.useState("");
  const [selectedPage, setSelectedPage] = React.useState("/gestion");

  React.useEffect(() => {
    const checkLogin = async () => {
      const storedPassword = window.localStorage.getItem("password");
      if (storedPassword) {
        try {
          setLoading(true);
          const response = await login(storedPassword);
          if (response.success) {
            setLoggedIn(true);
          }
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }
    };
    checkLogin();
  }, []);

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        loggedIn,
        setLoggedIn,
        domainsValue,
        setDomainsValue,
        selectedPage,
        setSelectedPage,
      }}
      {...props}
    />
  );
};

export default AppContextProvider;
