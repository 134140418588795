import * as React from "react";
import SelectMultiple from "../../common/select-multiple/select-multiple";
import Button from "@valraiso-esf/esf-ui/es/button";
import Input from "@valraiso-esf/esf-ui/es/input";
import Select from "@valraiso-esf/esf-ui/es/select";
import {
  createMonitorankKeywords,
  removeMonitorankKeywords,
} from "../../../services/api";
import { useMonitorankContext } from "../../common/contexts/monitorank-context";
import {
  convertKeywordToSelectMultiple,
  convertSearchConsoleToSelectMultiple,
} from "../../../utils/monitorank";

const LANG = ["fr", "en"];

const UpdateMonitorankSettings = () => {
  const { keywords, searchConsoles, action: mode } = useMonitorankContext();
  const [searchConsoleValues, setSearchConsoleValues] = React.useState(
    convertSearchConsoleToSelectMultiple(searchConsoles)
  );
  const [keywordsValues, setKeywordsValues] = React.useState(
    convertKeywordToSelectMultiple(keywords)
  );
  const [newKeyword, setNewKeyword] = React.useState("");
  const [newLang, setNewLang] = React.useState("fr");

  const addKeywords = async () => {
    await createMonitorankKeywords({
      keyword: newKeyword,
      lang: newLang,
      searchConsoles: searchConsoleValues.filter(
        (searchConsoleValue) => searchConsoleValue.selected
      ),
    });
  };

  const removeKeywords = async () => {
    await removeMonitorankKeywords({
      keywords: keywordsValues.filter(
        (keywordsValue) => keywordsValue.selected
      ),
      searchConsoles: searchConsoleValues.filter(
        (searchConsoleValue) => searchConsoleValue.selected
      ),
    });
  };

  return (
    <div className="monitorank__update">
      <div className="monitorank__update__selection">
        {mode === "SUPPRIMER" && (
          <SelectMultiple
            label="Mots clés"
            value={keywordsValues}
            onValueChange={setKeywordsValues}
            className="permissions__select-multiple monitorank__select-multiple"
          />
        )}{" "}
        {mode === "AJOUTER" && (
          <div className="monitorank__update_add">
            <span className="select-multiple__title">Nouveau mot-clé</span>
            <Input
              label="Mot-clé"
              value={newKeyword}
              onChange={(e) => {
                setNewKeyword(e.target.value);
              }}
            />
            <Select
              label="Langue"
              value={newLang}
              onValueChanged={(e) => setNewLang(e)}
            >
              {LANG.map((elmt) => {
                return <option key={elmt + "choice keyword"}>{elmt}</option>;
              })}
            </Select>
          </div>
        )}
        <div className="monitorank__separator" />
        <SelectMultiple
          label="Suffixes"
          value={searchConsoleValues}
          onValueChange={setSearchConsoleValues}
          className="permissions__select-multiple monitorank__select-multiple"
        />
      </div>
      <Button onClick={mode === "AJOUTER" ? addKeywords : removeKeywords}>
        {mode}
      </Button>
    </div>
  );
};

export default UpdateMonitorankSettings;
