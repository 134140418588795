import * as React from "react";
import { deleteGoals, createGoals } from "../../../services/api.js";
import { useGestionContext } from "../../common/contexts/getion-context.js";
import Popup from "../../common/popup.js";
import * as Icons from "../../icons";

const RestoreGoalPopup = ({ type, name }) => {
  const { setPopup, activeDomain, setActiveDomain } = useGestionContext();

  const submitForm = async () => {
    try {
      const kind = type.startsWith("event") ? "event" : "page";
      const body = {
        domains: [activeDomain.name],
        goals: [name],
      };
      await deleteGoals(body);
      await createGoals([activeDomain.name], [name.slice(6)], kind);
      setActiveDomain((activeDomain) => {
        const newGoals = activeDomain.goals.map((goal) => {
          if (goal.name === name) {
            return { ...goal, deleted: 0 };
          }
          return goal;
        });
        return { ...activeDomain, goals: newGoals };
      });
      setPopup(undefined);
    } catch (e) {
      setPopup({ name: "error" });
    }
  };

  const closePopup = () => {
    setPopup(undefined);
  };

  const buttons = [
    { message: "Annuler", action: closePopup },
    { message: "Restaurer", action: submitForm },
  ];

  return (
    <Popup CloseIcon={Icons.Close} closeEffect={closePopup} buttons={buttons}>
      <p className="popup-delete-text">
        Êtes vous sûr de vouloir restaurer{" "}
        {type.startsWith("event") ? "l'évènement" : "la page"}: <br />
        {name}
        <br /> de <br />{" "}
        <a className="popup-delete-text-domain" href={activeDomain.name}>
          {activeDomain.name}{" "}
        </a>{" "}
      </p>
    </Popup>
  );
};

export default RestoreGoalPopup;
