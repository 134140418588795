import * as React from "react";
import * as Icons from "../../icons";
import Tooltip from "@valraiso-esf/esf-ui/es/tooltip";
import Specificity from "./specificity";
import { useGestionContext } from "../../common/contexts/getion-context";

const Specificities = () => {
  const { setPopup, activeDomain } = useGestionContext();
  const { link } = activeDomain;
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(link);
    } else {
      document.execCommand("copy", true, link);
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const addSpe = () => {
    setPopup({ name: "addSpecificity" });
  };

  const deleteSpe = (e) => {
    e.stopPropagation();
    setPopup({ name: "removeSpecificity" });
  };

  const callback = (kind, name) => {
    setPopup({ name: "infoSpecificity", data: { name, kind } });
  };

  return (
    <div className="gestion-spe">
      <div className="gestion-spe__link">
        <h3 className="gestion-goals__title">Link</h3>
        <a
          className="gestion__link"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link}
        </a>
        <button className="gestion-spe__copy" onClick={copyToClipboard}>
          <Tooltip title={copied ? "Copié!" : "Copier"}>
            <Icons.Copy className="gestion_spe__copy-icon" />
          </Tooltip>
        </button>
      </div>
      <div className="gestion-spe__specificities">
        <h3 className="gestion-goals__title">Specificities</h3>
        <button className="gestion-spe__add__button">
          <Tooltip title="Ajouter une spécificité" position="left">
            <Icons.Add onClick={addSpe} className="gestion-spe__add" />
          </Tooltip>
        </button>
        <div className="gestion-spe__center">
          <div className="gestion-spe__content">
            {activeDomain.specificities &&
              activeDomain.specificities
                .filter((elmt) => elmt.kind !== "Sites")
                .map((spe, index) => {
                  return (
                    <Specificity
                      key={spe.name + activeDomain.name + index + "spe"}
                      spe={spe}
                      callback={callback}
                      removeCallback={deleteSpe}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specificities;
