import { post, _delete, get } from "../utils/fetcher.js";

const isProd = process.env.NODE_ENV === "production";
const api = isProd ? "/api" : "http://localhost:9030/api";

const getHeaders = () => {
  return { authorization: window.localStorage.getItem("password") };
};

export const login = async (password) => {
  const endpoint = `${api}/login/connect`;
  return post(endpoint, { password });
};

export const createDomains = async (domains, segmentationId, orderTypeId) => {
  const endpoint = `${api}/sites/add`;
  return post(
    endpoint,
    { domains, segmentationId, orderTypeId },
    undefined,
    getHeaders()
  );
};

export const deleteDomains = async (domains) => {
  const endpoint = `${api}/sites/remove`;
  return _delete(endpoint, { domains }, undefined, getHeaders());
};

export const createLinks = async (domains) => {
  const endpoint = `${api}/shared-links/add`;
  return post(endpoint, { domains }, undefined, getHeaders());
};

export const createGoals = async (domains, goals, kind) => {
  const endpoint = `${api}/goals/add`;
  return post(endpoint, { domains, goals, kind }, undefined, getHeaders());
};

export const refreshData = async () => {
  const endpoint = `${api}/scrapping/`;
  return get(endpoint, undefined, getHeaders());
};

export const getDomains = async () => {
  const endpoint = `${api}/sites/list`;
  return get(endpoint, undefined, getHeaders());
};

export const getGoals = async (domain) => {
  const endpoint = `${api}/goals/list/${domain}`;
  return get(endpoint, undefined, getHeaders());
};

export const getLink = async (domain) => {
  const endpoint = `${api}/shared-links/list/${domain}`;
  return get(endpoint, undefined, getHeaders());
};

export const deleteGoals = async (body) => {
  const endpoint = `${api}/goals/remove`;
  return _delete(endpoint, body, undefined, getHeaders());
};

export const restoreDomain = async (domain) => {
  const endpoint = `${api}/sites/recreate/${domain}`;
  return post(endpoint, { ok: "ok" }, undefined, getHeaders());
};

export const getLastSync = async () => {
  const endpoint = `${api}/scrapping/date`;
  return get(endpoint, undefined, getHeaders());
};

export const getSpecificities = async (domain) => {
  const endpoint = `${api}/sites/specificity`;
  const body = { domain };
  return post(endpoint, body, undefined, getHeaders());
};

export const addSpecificity = async (body) => {
  const endpoint = `${api}/specificity/add`;
  return post(endpoint, body, undefined, getHeaders());
};

export const removeSpecificity = async (body) => {
  const endpoint = `${api}/specificity/remove`;
  return _delete(endpoint, body, undefined, getHeaders());
};

export const getDomainsBySpecificity = async (body) => {
  const endpoint = `${api}/specificity/get-domains`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getTokensList = async () => {
  const endpoint = `${api}/user/list`;
  return get(endpoint, undefined, getHeaders());
};

export const removeToken = async (body) => {
  const endpoint = `${api}/user/remove`;
  return _delete(endpoint, body, undefined, getHeaders());
};

export const addToken = async (body) => {
  const endpoint = `${api}/user/create`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getTokenPerms = async (token) => {
  const endpoint = `${api}/user/perm-list/${token}`;
  return get(endpoint, undefined, getHeaders());
};

export const updateTokenPerms = async (body) => {
  const endpoint = `${api}/user/update-perm`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getIncomeSettings = async (domain) => {
  const endpoint = `${api}/tables-parameters/get/${domain}`;
  return get(endpoint, undefined, getHeaders());
};

export const updateIncomeSettings = async (body) => {
  const endpoint = `${api}/tables-parameters/update`;
  return post(endpoint, body, undefined, getHeaders());
};

export const updateDomainIncomeSettings = async (body) => {
  const endpoint = `${api}/tables-parameters/update-domain`;
  return post(endpoint, body, undefined, getHeaders());
};

export const addDomainIncomeSettings = async (body) => {
  const endpoint = `${api}/tables-parameters/create-type`;
  return post(endpoint, body, undefined, getHeaders());
};

export const getMonitorankKeywords = async () => {
  const endpoint = `${api}/seo/monitorank/get-keywords`;
  return get(endpoint, undefined, getHeaders());
};

export const getSearchConsoleKeywords = async () => {
  const endpoint = `${api}/seo/monitorank/get-search-console`;
  return get(endpoint, undefined, getHeaders());
};

export const createMonitorankKeywords = async (body) => {
  const endpoint = `${api}/seo/monitorank/create`;
  return post(endpoint, body, undefined, getHeaders());
};

export const removeMonitorankKeywords = async (body) => {
  const endpoint = `${api}/seo/monitorank/remove`;
  return _delete(endpoint, body, undefined, getHeaders());
};

export const getSiteOptions = async () => {
  const endpoint = `${api}/sites/get-options`;
  return get(endpoint, undefined, getHeaders());
};
