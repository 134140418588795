import * as React from "react";
import { useGestionContext } from "../common/contexts/getion-context.js";
import Popup from "../common/popup.js";
import * as Icons from "../icons";

const ErrorPopup = () => {
  const { setPopup } = useGestionContext();

  const closePopup = () => {
    setPopup(undefined);
  };

  const buttons = [{ message: "Fermer", action: closePopup }];

  return (
    <Popup CloseIcon={Icons.Close} closeEffect={closePopup} buttons={buttons}>
      <h2 className="popup-title">Erreur</h2>
      <p className="popup__text">L'action n'a pas pu être réalisée</p>
    </Popup>
  );
};

export default ErrorPopup;
