import * as React from "react";
import classnames from "classnames";
import * as Icons from "../../icons.js";
import Dropdown from "../../common/dropdown.js";
import { useGestionContext } from "../../common/contexts/getion-context.js";

const Goal = ({ goal }) => {
  const { setPopup } = useGestionContext();
  const { kind, name, deleted } = goal;
  const formatedName = name.replace("Visit ", "");

  const deleteGoal = async (e) => {
    setPopup({
      name: "deleteGoal",
      data: { type: kind, name: kind === "page" ? name : formatedName },
    });
  };

  const restoreGoal = async () => {
    setPopup({ name: "restoreGoal", data: { type: kind, name } });
  };

  const classes = classnames("goal", {
    "goal--deleted": deleted,
  });

  const defaultDomainOption = {
    Icon: Icons.Delete,
    label: "Supprimer",
    callback: deleteGoal,
  };
  const deletedDomainOption = {
    Icon: Icons.Restore,
    label: "Restaurer",
    callback: restoreGoal,
  };

  const domainOptions = deleted
    ? [deletedDomainOption, defaultDomainOption]
    : [defaultDomainOption];

  return (
    <div className={classes}>
      {formatedName}
      <div className="goal__icons">
        <Dropdown
          className="goal__parameters"
          classNameButton="goal__options"
          options={domainOptions}
        />
      </div>
    </div>
  );
};

export default Goal;
